export const Colors = {
  orange: '#f57c00',
  blue: '#039be5',
  black: '#222222',
  white: '#ffffff',
  mediumGray: '#6e6869',
  red: '#fc5c65',
  brown_border: '#512b1e', //Caput Mortuum
  brown_font: '#b46a1f', //dirty brown
  dessert_sand: '#e2cfa3',
  brown_derby:'#542c1c',
  bourbon: '#b46c1c',
  zombie: '#e4d4a4',
  matrix: '#b46464',
  electric_brown: '#b46464',
  bronze: '#a68c59',
  chocolate: '#7A3A00',
  caput: '#542c1c',
  ligth_brown: '#b46c1c'
};
