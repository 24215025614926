import React, { ButtonHTMLAttributes } from 'react';
import { TextInput as RNTextInput } from 'react-native';

import { View } from './View';
import { Icon } from './Icon';
import Button from './Button';
import { Button2 } from './Button2';
import { colors } from '../colors';

type RNTextInputProps = React.ComponentProps<typeof RNTextInput>;

interface MyComponentProps extends RNTextInputProps {
  name: string;
  width?: string;
  leftIconName?: string;
  rightIcon?: string;
  handlePasswordVisibility?: () => void;
}

export const TextInput: React.FC<MyComponentProps> = ({
  name,
  width = '100%',
  leftIconName,
  rightIcon,
  handlePasswordVisibility,
  ...otherProps
}) => {
  return (
    <View
      style={{
        backgroundColor: colors.white,
        borderRadius: 8,
        flexDirection: 'row',
        padding: 12,
        marginVertical: 12,
        width,
        borderWidth: 1,
        borderColor: colors.mediumGray
      }}
    >
      {leftIconName ? (
        <Icon
          name={leftIconName}
          size={22}
          color={colors.mediumGray}
          style={{ marginRight: 10 }}
        />
      ) : null}
      <RNTextInput
        style={{
          flex: 1,
          width: '100%',
          fontSize: 18,
          color: colors.black
        }}
        placeholderTextColor={colors.mediumGray}
        {...otherProps}
      />
      {rightIcon ? (
        <Button2 onPress={handlePasswordVisibility}>
          <Icon
            name={rightIcon}
            size={22}
            color={colors.mediumGray}
            style={{ marginRight: 10 }}
          />
        </Button2>
      ) : null}
    </View>
  );
};
