const config = {
  columnNames: [
    'Id',
    'Medidor',
    'Nombre',
    'Status',
    'Manzana',
    'Lote',
  ],
  keys: ['id', 'meterNumber', 'customer.Name', 'status', 'block', 'lot'],
  fileName: 'contratos.xlsx',
  sheetName: 'Contratos de Agua',
};

export { config };
