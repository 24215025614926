import { Timestamp } from "firebase/firestore";
import { IContract, Customer, Payment, Address } from "../ContractDetails";
import {PaymentStatus} from "../PaymentStatus";

var customer: Customer = {};
var address: Address = {};

address.city = "Saltillo";
address.country = "MX";
address.line1 = "Calle Principal #123 Fracc. Peña Alta, CP 25310";
address.postalCode = "25000";
address.state = "Coahuila";

customer.email = "test@test.com";
customer.name = "Pedro Martinez";
customer.phone = "+52 844-123-4567";
customer.address = address;

const contract: IContract = {
    id: 123 + "",
    payments: [],
    initialMeasurement: 0,
    initialPaymentAmount: 0,
    customer: customer, 
    status: "Active",
    users: [customer.email],
    address: address
};

for (let i = 0; i < 12; i++) {
    var payment: Payment = {
        id: i + "",
        amount: 0,
        measurementDate: Timestamp.fromDate(new Date(2022, i, 1, 0, 0, 0, 0)),
        dueDate: Timestamp.fromDate(addDays(20, new Date(2022, i, 1, 0, 0, 0, 0))),
        usage: 0,
        //isPaid: (i + 1) % 2 == 0,
        previousRead: (i + 1) * 100,
        currentRead: (i + 2) * 100,
        unitPrice: 10,
        status: PaymentStatus.new,
        //waterReserve: "DEL RIO"
    };
    payment.usage = payment.currentRead - payment.previousRead
    payment.amount = payment.usage * payment.unitPrice;
    contract.payments.push(payment)
}

contract.payments.sort(
    (objA, objB) => objB.measurementDate.seconds - objA.measurementDate.seconds,
);

function addDays(numOfDays: number, date = new Date()) {
    date.setDate(date.getDate() + numOfDays);
    return date;
}

export const Contract = contract;