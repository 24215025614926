
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native';
import { colors } from '../colors';
//import { fetchPublishableKey } from '../helpers';
import { en, es } from '../localizations';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';


interface Props {
  paymentMethod?: string;
  children?: any;
}
const i18n = new I18n({ en, es } );

// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;

// When a value is missing from a language it'll fallback to another language with the key present.
i18n.enableFallback = true;

const PaymentScreen: React.FC<Props> = ({ paymentMethod, children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function initialize() {
      const publishableKey = 1;// await fetchPublishableKey(paymentMethod);
      if (publishableKey) {
       
        setLoading(false);
      }
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <ActivityIndicator size="large" style={StyleSheet.absoluteFill} />
  ) : (
    <View
      accessibilityLabel="payment-screen"
      style={styles.container}
     >
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <Text style={{ opacity: 0, height:0 }}>appium fix</Text>
      {children}
    </View>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingTop:0,
    paddingHorizontal: 0,
    //paddingLeft:8,
    paddingBottom:0
  },
});

export default PaymentScreen;
