import React, { useCallback, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Linking, StyleSheet, View, ScrollView, Platform, StatusBar } from 'react-native';
import { colors } from '../colors';
import Button from '../components/Button';
import { Collapse } from '../components/Collapse';

import { en, es } from '../localizations';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../RootStackParamsList';
import { Contract } from '../models/data/Contracts';
import { signOut } from 'firebase/auth';
import { auth } from '../config2';

const i18n = new I18n({ en, es });
i18n.locale = Localization.locale;
i18n.enableFallback = true;
const showOtherMenus = false;


export function HomeScreen() {

  const handleLogout = () => {
    signOut(auth).catch(error => console.log('Error logging out: ', error));
  };

  const navigation = useNavigation();
  const navigation2 = useNavigation<StackNavigationProp<RootStackParamList>>();


  return (
    <ScrollView accessibilityLabel="app-root" style={styles.container}>
      <>
        <View style={styles.buttonContainer}>
          <Button title='Sign Out' onPress={handleLogout} />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            title="Imprimir Recibo"
            onPress={() => {
              navigation.navigate(i18n.t('invoiceScreenTitle'));
            }}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            title="Pagos - Pantalla de Tarjeta"
            onPress={() => {
              navigation.navigate(i18n.t('payWithCard'));
            }}
          />
        </View>

        <View style={styles.buttonContainer}>
          <Button
            title={i18n.t('scanMeterCode')}
            onPress={() => {
              navigation.navigate(i18n.t('scanMeterCode'));
            }}
          />
        </View>

        <View style={styles.buttonContainer}>
          <Button
            title={'PaymentsListScreenType'}
            onPress={() => {
              navigation2.navigate('PaymentsListScreenType', { contract: Contract });
            }}
          />
        </View>
      </>


    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
  },
  buttonContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderBottomColor: colors.light_gray,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});
