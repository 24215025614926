import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export const Icon = ({ name, size, color, style }:{name:any, size:any, color:any, style:any}) => {
  return (
    <MaterialCommunityIcons
      name={name}
      size={size}
      color={color}
      style={style}
    />
  );
};
