import * as Yup from 'yup';
import { en, es } from '../localizations';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

const i18n = new I18n({ en, es });
i18n.locale = Localization.locale;
i18n.enableFallback = true;

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required(i18n.t('requiredField')).email().label('Email'),
  password: Yup.string().required(i18n.t('requiredField')).min(6, i18n.t('passwordMustBe')).label('Password')
});

export const signupValidationSchema = Yup.object().shape({
  email: Yup.string().required(i18n.t('requiredField')).email().label('Email'),
  password: Yup.string().required(i18n.t('requiredField')).min(6, i18n.t('passwordMustBe')).label('Password'),
  contractId: Yup.string().required(i18n.t('requiredField')).label('contract'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], i18n.t('passwordMustMatch'))
    .required(i18n.t('passwordRequired'))
});

export const passwordResetSchema = Yup.object().shape({
  email: Yup.string()
    .required(i18n.t('pleaseEnterRegisteredMail'))
    .label('Email')
    .email(i18n.t('enterValidM'))
});

const positiveNumberRegExp = /^\d*[1-9]\d*$/gm
export const meterMeasurementValidationSchema = Yup.object().shape({
  meterMeasurement: Yup.string().matches(positiveNumberRegExp, i18n.t('onlyPositiveNumbersAllowed')).required(i18n.t('requiredField')).label('meterMeasurement'),
  repeatMeterMeasurement: Yup.string().matches(positiveNumberRegExp, i18n.t('onlyPositiveNumbersAllowed')).oneOf([Yup.ref('meterMeasurement')], i18n.t('measurementMustMatch'))
    .required(i18n.t('requiredField')).label("repeatMeterMeasurement")
});
