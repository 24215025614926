const en = {
    contractTitle: "Contract Number",
    paymenstScreenTitle: "Payments",
    invoiceScreenTitle: "Bill",
    printBill: "Print Bill",
    saveBill: "Save as Pdf",
    print: "Print",
    saveAsPdf: "Save as Pdf",
    save: "Save",
    scanMeterCode: "Scan Meter QR",
    scanAgain: "Volver a Leer Codigo",
    captureMeterData: "Capture Meter Data",
    login: "Login",
    mail: "Mail*",
    password: "Password*",
    createNewAccount: "Create New Account",
    resetPassword: "Reset Password",
    alreadyHaveAccount: "Already Have Account",
    requiredField: "Required field",
    signUp: "Signup",
    enterValidMail: "Enter a valid email",
    pleaseEnterRegisteredMail: "Please enter a registered email",
    passwordMustMatch: "Verify passwords match",
    passwordRequired: "Password is required",
    passwordMustBe: "Password must be at least 6 characters long",
    contractNumber: "Contract Number",
    contractId: "Contract Number",
    sendResetMail: "Send reset mail",
    goBackToLogin: "Go back to login",
    resetYourPassword: "Reset your password",
    repeatPassword: "Repeat password",
    meterMeasurement: "Meter Measurement",
    payWithCard: "Pay with card",
    userAlreadyExist: "The user is already linked to a contract",
    contractNoExist: "Contract does not exist",
    onlyTwoUsersAllowed: "The contract already have two users linked, contact peña alta",
    scanErrorContractNotFound: "Contract was not found, try it again",
    measurementMustMatch: "Verify measurement match",
    newMeasurementRead: "New measurement has been read succesfully",
    measurementAlreadyRead: "The measurement has been already read, Please, contact your admin team to overwrite it",
    monthRead: "Month read",
    saveButton: "Save",
    onlyPositiveNumbersAllowed: "Only positive numbers are allowed",
    PAID: "Paid",
    OVERDUE: "Overdue",
    DELETED: "Deleted",
    NEW: "Pending Payment",
    ACTIVE: "Pending Payment",
}

const es = {
    contractTitle: "Número de Contracto",
    paymenstScreenTitle: "Pagos",
    invoiceScreenTitle: "Recibo",
    printBill: "Imprimir Recibo",
    saveBill: "Guardar Recibo",
    print: "Imprimir",
    saveAsPdf: "Guardar Pdf",
    save: "Guardar",
    scanMeterCode: "Leer Codigo de Medidor",
    scanAgain: "Volver a Leer Codigo",
    captureMeterData: "Capturar Lectura de Medidor",
    login: "Inicia Sesion",
    mail: "Correo*",
    password: "Contraseña*",
    createNewAccount: "Crear Cuenta Nueva",
    resetPassword: "Restablecer Contraseña",
    alreadyHaveAccount: "Ya tengo cuenta",
    requiredField: "Campo requerido",
    signUp: "Registrate",
    enterValidMail: "Introduce un correo valido",
    pleaseEnterRegisteredMail: "Usa un correo registrado",
    passwordMustMatch: "Verifica que las contraseñas coincidan",
    passwordRequired: "Contraseña es requerida",
    passwordMustBe: "Contraseña debe ser de al menos 6 caracteres",
    contractNumber: "No. de Contrato",
    contractId: "No. de Contrato",
    sendResetMail: "Enviar correo de restablecimiento",
    goBackToLogin: "Regresar a Inicio de Sesion",
    resetYourPassword: "Restablecer Contraseña",
    repeatPassword: "Repetir contraseña*",
    meterMeasurement: "Lectura Medidor",
    payWithCard: "Pago con tarjeta",
    userAlreadyExist: "El usuario ya esta dado de alta con otro contrato",
    contractNoExist: "El contrato no existe",
    onlyTwoUsersAllowed: "El contracto ya tiene dos usuarios enlazados, contacta a peña alta",
    scanErrorContractNotFound: "El contrato no existe, vuelve a intentar",
    measurementMustMatch: "Verifica que las medidas coincidan",
    newMeasurementRead: "Nueva medición guardada",
    measurementAlreadyRead: "La medición ya fue tomada, Por Favor, contacta al equipo administrativo para sobreescribirlo",
    monthRead: "Mes leído",
    saveButton: "Guardar",
    onlyPositiveNumbersAllowed: "Solo números positivos son permitidos",
    PAID: "Pagado",
    OVERDUE: "Vencido",
    DELETED: "Borrado",
    NEW: "Pendiente de Pago",
    ACTIVE: "Pendiente de Pago",
}

export { en, es }
