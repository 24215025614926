import { User } from 'firebase/auth';
import React, { useState, createContext } from 'react';

interface Props {
  children: React.ReactNode;
}
export const AuthenticatedUserContext = createContext({});

export const AuthenticatedUserProvider: React.FC<Props> = ({ children }) => {
  const [userMode, setUserMode] = React.useState<User | null>(null);

  return (
    <AuthenticatedUserContext.Provider value={{ user: userMode, setUser: setUserMode }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
