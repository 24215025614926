import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { RootNavigator } from './navigation/RootNavigator';
import { AuthenticatedUserProvider } from './providers';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { registerLicense } from '@syncfusion/ej2-base';

const queryClient = new QueryClient()
import { LogBox } from 'react-native';
//LogBox.ignoreLogs(["Constants.platform.ios.model.* has been deprecated in favor of expo-device's Device.modelName property. This API will be removed in SDK 45."]); // Ignore log notification by message
LogBox.ignoreAllLogs();//Ignore all log notifications
registerLicense("Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQl5hQH5Rc0xhX3lXdnc=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSX1Rf0VhXHZcdnNTRWM=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdExjW3pXcnJSTmBf;MTUyNzgyNEAzMjMxMmUzMTJlMzMzNWQrNHoreGJiRWMwNjZOMW0veDZkWFBjWWNtOWthNkhCUmY3dHRGTVJMWnc9;MTUyNzgyNUAzMjMxMmUzMTJlMzMzNUo3NXp1TnVMM2E5NjRpakhxakl2WjhkMUwwanZ5TWJybDQySHR1WmZTczA9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUdqWX1beXZTTmVZUg==;MTUyNzgyN0AzMjMxMmUzMTJlMzMzNUhOejZrdWtaRGw4R2Z2Q2NaS2NKb2lXSEoyRXhnTmc4UTk1dnp3RW8xcW89;MTUyNzgyOEAzMjMxMmUzMTJlMzMzNUUyemZKNjkyWmpwYXpJRzBxdndGdkVKWW5Na29VMmt1bW1zWE1Ddk1iMW89;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdExjW3pXcnJcRWJf;MTUyNzgzMEAzMjMxMmUzMTJlMzMzNVRVajlEUWFoOE93TVJzeDFxaGhIRDhBZmZkUG9KU2E4akJUdmZzNVV3VFk9;MTUyNzgzMUAzMjMxMmUzMTJlMzMzNU9XRmZoUTlOdldCTmVlaWlsTEVpWnQzRHlGT3dQK3hzUlA5VnhIN2ZJUGc9;MTUyNzgzMkAzMjMxMmUzMTJlMzMzNUhOejZrdWtaRGw4R2Z2Q2NaS2NKb2lXSEoyRXhnTmc4UTk1dnp3RW8xcW89");

import {L10n, setCulture} from '@syncfusion/ej2-base';
setCulture('mx-ES');
setCulture('es-MX');
L10n.load({
    'mx-ES': {
       'grid': {
             'ConfirmDelete': '¿Estas seguro de que deseas eliminar el registro?',
             'date':'Date de commande'
         }
     }
});


export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <AuthenticatedUserProvider>
      <SafeAreaProvider>
        <RootNavigator />
      </SafeAreaProvider>
    </AuthenticatedUserProvider>
    </QueryClientProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
