
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import firebaseDB from "../config2/firebase";
import { IContract, Payment } from "../models/ContractDetails";
import { PaymentStatusType } from '../models/PaymentStatus';

const findById = async (paymentId: any, contractId: any): Promise<Payment> => {
    try {
        const q = query(collection(firebaseDB, "contracts", contractId, "payments"), where("id", "==", paymentId));
        const querySnapshot = await getDocs(q);

        const payment: Payment = querySnapshot.empty ?
            {} as Payment : querySnapshot.docs[0].data() as Payment;
        return payment;
    } catch (error) {
        console.log(error);
        return {} as Payment;
    }
}

const getLast = async (contractId: any): Promise<Payment> => {
    try {
        const q = query(
            collection(firebaseDB, "contracts", contractId, "payments"),
            orderBy("measurementDate", "desc"),
            limit(1));
        const querySnapshot = await getDocs(q);
        const payment: Payment = querySnapshot.empty ?
            {} as Payment : querySnapshot.docs[0].data() as Payment;
        return payment;
    } catch (error) {
        console.log(error);
        return {} as Payment;
    }
}

const getAll = async (contractId: any): Promise<Payment[]> => {
    try {
        let paymentsResult = [];
        const paymentsQuery = query(
            collection(firebaseDB, "contracts", contractId, "payments"),
            orderBy("measurementDate", "desc")
        );
        const payments = await getDocs(paymentsQuery);

        if (payments.empty) {
            return {} as Payment[];
        }

        payments.forEach((doc) => {
            let payment: Payment = doc.data() as Payment;
            paymentsResult.push(payment);
        });

        return paymentsResult;

    } catch (error) {
        console.log(error);
        return {} as Payment[];
    }
}

const update = async (paymentId: any, contractId: any, i: Payment): Promise<Payment> => {
    try {
        const q = query(collection(firebaseDB, "contracts", contractId, "payments"), where("id", "==", paymentId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("Payment not found")
            return {} as Payment;
        }

        let payment: Payment = querySnapshot.docs[0].data() as Payment;
        await updateDoc(querySnapshot.docs[0].ref, {
            ...i
        });

        return payment;
    } catch (error) {
        console.log(error);
        return {} as Payment;
    }
}

const updatePaymentStatus = async (paymentId: string, contractId: string, status: PaymentStatusType): Promise<Payment> => {
    try {
        const q = query(collection(firebaseDB, "contracts", contractId, "payments"), where("id", "==", paymentId));
        const querySnapshot = await getDocs(q);

        let payment: Payment = querySnapshot.empty ?
            {} as Payment : querySnapshot.docs[0].data() as Payment;
        payment.status = status;
        payment.paidDate = Timestamp.fromDate(new Date());

        await updateDoc(querySnapshot.docs[0].ref, {
            status: status,
            paidDate: Timestamp.fromDate(new Date())
        });

        return payment;
    } catch (error) {
        console.log(error);
        return {} as Payment;
    }
}

const create = async (payment: Payment, contractId: any): Promise<Payment> => {
    try {
        const docRef = doc(firebaseDB, "contracts", contractId);
        let p = payment;
        const c = collection(docRef, "payments");
        const paymentsRef = doc(c)
        p.id = paymentsRef.id;

        p.period = calculatePeriod(p);

        await setDoc(paymentsRef, p)
        return payment;
    } catch (error) {
        console.log(error);
        return {} as Payment;
    }
}

const deletePayment = async (paymentId: string, contractId: string): Promise<Boolean> => {
    try {
        const q = query(collection(firebaseDB, "contracts", contractId, "payments"), where("id", "==", paymentId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log(`Could not find payment ${paymentId} for contract ${contractId}`)
        }
        await deleteDoc(querySnapshot.docs[0].ref);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

const PaymentService = {
    findById,
    getAll,
    create,
    update,
    updatePaymentStatus,
    deletePayment
}

export default PaymentService;

function calculatePeriod(p: Payment): import("@firebase/firestore").Timestamp {
    const date = new Date(p.measurementDate.seconds * 1000);
    let previousDate = date;
    previousDate.setMonth(date.getMonth() - 1);
    return Timestamp.fromDate(previousDate);
}
