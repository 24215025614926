import React, { useState } from 'react';
import { Text, StyleSheet } from 'react-native';
import { Formik } from 'formik';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { View, TextInput, Logo, Button2 as Button, FormErrorMessage } from '../components';
import { Images, Colors, auth } from '../config2';
import { useTogglePasswordVisibility } from '../hooks';
import { signupValidationSchema } from '../utils';

import { en, es } from '../localizations';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import ContractService from '../services/ContractService';
import { createUserWithEmailAndPassword } from 'firebase/auth';

const i18n = new I18n({ en, es });
i18n.locale = Localization.locale;
i18n.enableFallback = true;

export const SignupScreen = ({ navigation }) => {
  const [errorState, setErrorState] = useState('');

  const {
    passwordVisibility,
    handlePasswordVisibility,
    rightIcon,
    handleConfirmPasswordVisibility,
    confirmPasswordIcon,
    confirmPasswordVisibility
  } = useTogglePasswordVisibility();

  const handleSignup = async (values: { email: string, password: string, contractId: string }) => {
    const { email, password, contractId } = values;
    try {
      const isValidContract = await ContractService.isValidContract(contractId);

      if (isValidContract) {
        await createUserWithEmailAndPassword(auth, email, password);
        await ContractService.updateUsers(contractId, email);
      } else {
        setErrorState(i18n.t('contractNoExist'));
      }
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorState(i18n.t('userAlreadyExist'));
      } else {
        setErrorState(i18n.t(error.response.data.code));
      }
    }
  };

  return (
    <View isSafe style={styles.container}>
      <KeyboardAwareScrollView enableOnAndroid={true}>
        {/* LogoContainer: consits app logo and screen title */}
        <View style={styles.logoContainer}>
          <Logo uri={Images.logo} />
          <Text style={styles.screenTitle}>{i18n.t('createNewAccount')}</Text>
        </View>
        {/* Formik Wrapper */}
        <Formik
          initialValues={{
            email: '',
            password: '',
            confirmPassword: '',
            contractId: ''
          }}
          validationSchema={signupValidationSchema}
          onSubmit={values => handleSignup(values)}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            handleBlur
          }) => (
            <>
              {/* Input fields */}
              <TextInput
                name='email'
                leftIconName='email'
                placeholder={i18n.t('mail')}
                autoCapitalize='none'
                keyboardType='email-address'
                textContentType='emailAddress'
                autoFocus={true}
                value={values.email}
                onChangeText={handleChange('email')}
                onBlur={handleBlur('email')}
              />

              <FormErrorMessage error={errors.email} visible={touched.email} />
              <TextInput
                name='contract'
                leftIconName='file-sign'
                placeholder={i18n.t('contractId')}
                autoCapitalize='none'
                keyboardType='number-pad'
                //textContentType='none'
                autoFocus={false}
                value={values.contractId}
                onChangeText={handleChange('contractId')}
                onBlur={handleBlur('contractId')}
              />
              <FormErrorMessage
                error={errors.contractId}
                visible={touched.contractId}
              />
              <TextInput
                name='password'
                leftIconName='key-variant'
                placeholder={i18n.t('password')}
                autoCapitalize='none'
                autoCorrect={false}
                secureTextEntry={passwordVisibility}
                textContentType='newPassword'
                rightIcon={rightIcon}
                handlePasswordVisibility={handlePasswordVisibility}
                value={values.password}
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
              />
              <FormErrorMessage
                error={errors.password}
                visible={touched.password}
              />
              <TextInput
                name='confirmPassword'
                leftIconName='key-variant'
                placeholder={i18n.t('repeatPassword')}
                autoCapitalize='none'
                autoCorrect={false}
                secureTextEntry={confirmPasswordVisibility}
                textContentType='password'
                rightIcon={confirmPasswordIcon}
                handlePasswordVisibility={handleConfirmPasswordVisibility}
                value={values.confirmPassword}
                onChangeText={handleChange('confirmPassword')}
                onBlur={handleBlur('confirmPassword')}
              />
              <FormErrorMessage
                error={errors.confirmPassword}
                visible={touched.confirmPassword}
              />
              {/* Display Screen Error Mesages */}
              {errorState !== '' ? (
                <FormErrorMessage error={errorState} visible={true} />
              ) : null}
              {/* Signup button */}
              <Button style={styles.button} onPress={handleSubmit}>
                <Text style={styles.buttonText}>{i18n.t('signUp')}</Text>
              </Button>
            </>
          )}
        </Formik>
        {/* Button to navigate to Login screen */}
        <Button
          style={styles.borderlessButtonContainer}
          borderless
          title={i18n.t('alreadyHaveAccount')}
          onPress={() => navigation.navigate('Login')}
        />
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: 12
  },
  logoContainer: {
    alignItems: 'center'
  },
  screenTitle: {
    fontSize: 32,
    fontWeight: '700',
    color: Colors.black,
    paddingTop: 20
  },
  button: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    backgroundColor: Colors.brown_border,
    padding: 10,
    borderRadius: 8
  },
  buttonText: {
    fontSize: 20,
    color: Colors.white,
    fontWeight: '700'
  },
  borderlessButtonContainer: {
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center'
  }
});
