export const colors = {
  //blurple: '#635BFF',
  //blurple_dark: '#5851DF',
  white: '#FFFFFF',
  light_gray: '#F6F9FC',
  dark_gray: '#425466',
  slate: '#0A2540',
  brown_border: '#512b1e', //Caput Mortuum
  brown_font: '#b46a1fd0', //dirty brown
  //brown_font: '#512b1e', //dirty brown
  brown_font2: '#b46a1f25', //dirty brown
  dessert_sand: '#e2cfa3',
  brown_derby:'#542c1c',
  bourbon: '#b46c1c',
  zombie: '#e4d4a4',
  matrix: '#b46464',
  electric_brown: '#b46464',
  bronze: '#a68c59',
  chocolate: '#7A3A00',
  caput: '#542c1c',
  ligth_brown: '#b46c1c',
  blurple: '#512b1e',
  blurple_dark: '#b46a1f',
  //black: '#000000',
  orange: '#f57c00',
  blue: '#039be5',
  black: '#222222',
  //white: '#ffffff',
  mediumGray: '#6e6869',
  red: '#fc5c65',
};
