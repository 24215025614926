import React, { Children, useEffect, useState } from 'react';
import { FlatList, Text, StyleSheet, TouchableOpacity, ListRenderItemInfo, ActivityIndicator } from 'react-native';
import Button from '../components/Button';
//import {Button as btn} from 'react-native';
import Btn from '@mui/material/Button';
import { View } from 'react-native';
import PaymentScreen from '../components/PaymentScreenNoScroll';
import { colors } from '../colors';
import { i18n } from '../i18n'
import { IContract, Payment } from '../models/ContractDetails';
import { signOut } from 'firebase/auth';
import { auth } from '../config2';
import { useIsFocused, useNavigation } from "@react-navigation/native";

import ContractService from '../services/ContractService';
import { useQuery } from 'react-query';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { useReactNavigationQuery } from '../services/useReactNavigationQuery';
import { RootStackParamList } from '../RootStackParamsList';
import { StackScreenProps } from '@react-navigation/stack';
import { AuthenticatedUserContext } from '../providers';
import { UserContextType } from '../@types/user';
import { Contract } from '../models/data/Contracts';
import { DataGrid, GridRowsProp, GridColDef, GridToolbar, esES, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridPrintExportMenuItem, GridToolbarQuickFilter, GridCellParams, GridApi, GridEventListener } from '@mui/x-data-grid';
import { ExportButton } from '../components/export/ExportButton';
import clsx from 'clsx';
import { Box } from '@mui/material';
import PaymentsGrid from '../components/grids/PaymentsGrid';
import ContractsGrid from '../components/grids/ContractsGrid';
import ContractsGrid2 from '../components/grids/ContractsGrid2';
import MasterDetail from '../components/grids/MasterDetail';
import NormalEdit from '../components/grids/NormalEdit';
import MasterDetailPre from '../components/grids/MasterDetailPre';


const showUnits = false;
//Device.osName;
type Props = StackScreenProps<RootStackParamList, 'ContractsScreen', 'MyStack'>;
export default function ContractsScreen(//{ route, navigation }: Props
) {
  const focus = useIsFocused();
  const { user, setUser } = React.useContext(AuthenticatedUserContext) as UserContextType;
  const navigation = useNavigation();
  //const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const [loading, setLoading] = useState(true);
  const [contractFromApi, setContractFromApi] = useState<IContract | undefined>();
  const [selectedContractPayments, setSelectedContractPayments] = useState<Payment[] | undefined>()


  const { data: contracts, isLoading: isLoadingContract, refetch: getContracts } = useQuery({
    queryKey: ['getContracts'],
    queryFn: () => ContractService.getLimited(100),
    refetchInterval: 10 * 600 * 1000,
    refetchOnWindowFocus: false,
  })

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      //Promise.resolve(getContract);      
      console.log("focused");
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);



  /* useFocusEffect(
     useCallback(() => {
       console.log("callback");
       getContract;
   }, [getContract]))*/

  const cardHeight = 0; //Device.osName == "iOS" ? 140 : 160;


  const handleLogout = () => {
    signOut(auth).catch(error => console.log('Error logging out: ', error));
  };

  const handleEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    //alert(`Movie "${params.row.id}" clicked`);
    setSelectedContractPayments(params.row.payments);
  };

  if (isLoadingContract) return <ActivityIndicator size="large" style={StyleSheet.absoluteFill} />;
  return (
    <PaymentScreen>

      <MasterDetail style={{ flex: 0.05, alignContent: 'stretch' }}
        InitialContracts={contracts}/>

      <View style={{ flex: 0.05, alignContent: 'stretch' }}>
        <TouchableOpacity style={{ flex: 1, flexDirection: 'row', backgroundColor: colors.brown_border, alignItems: 'center', alignContent: 'center' }} onPress={handleLogout}>
          <Text style={styles.buttonText}>Sign Out</Text>
        </TouchableOpacity>
      </View>
    </PaymentScreen >
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <ExportButton />
    </GridToolbarContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 0,
    //borderRadius: 12,
    //height: 20,
    borderColor: colors.red,
    borderWidth: 2
  },
  container2: {
    paddingVertical: 0,
    //borderRadius: 12,
    //height: 20,
    borderColor: colors.red,
    borderWidth: 2,
    flex: 0.9
  },
  button: {
    //width:,
    //justifyContent: 'flex-start',
    //alignContent: 'flex-start',
    //alignSelf:'stretch',
    minWidth: 145,
    alignItems: 'center',
    marginTop: 3,
    backgroundColor: colors.brown_font,
    padding: 10,
    borderRadius: 8,
    marginLeft: 'auto',
    //borderColor: colors.brown_font,
    //borderWidth: 3,
    color: colors.brown_font

  },
  button2: {
    //width: 'strecht',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignSelf: 'stretch',
    minWidth: 150,
    alignItems: 'center',
    flex: 1,
    //marginTop: 3,
    backgroundColor: colors.brown_border,
    padding: 20,
    borderRadius: 0,
    //marginLeft: 'auto',
    //borderColor: colors.brown_font,
    //borderWidth: 3,
    color: colors.brown_font,
  },
  buttonText: {
    fontSize: 20,
    color: colors.white,
    fontWeight: '700',
    textAlign: 'center',
    flex: 1
  },
  borderlessButtonContainer: {
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center'
  },
  itemFont: {
    fontSize: 35,
    textAlign: 'center',
    color: colors.zombie,
    fontWeight: "600"
  },
  superScript: {
    fontSize: 15,
    lineHeight: 27,
    color: colors.zombie,
    fontWeight: "600"
  }
});