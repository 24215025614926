import axios from "axios";
import { collection, getDocs, query, limit, doc, setDoc, updateDoc, where } from "firebase/firestore";
import { API_URL } from "../Config";
import firebaseDB from "../config2/firebase";
import { IContract } from "../models/ContractDetails";
import PaymentService from "./PaymentService";


const apiClient = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-type": "application/json",
  },
});

/*const findAll = async () => {
  const response = await apiClient.get<IContract[]>("/contracts-all");
  console.log(response);
  return response.data;
}*/

const findAll = async (): Promise<IContract[]> => {
  try {
    let contracts: IContract[] = [];

    const querySnapshot = await getDocs(collection(firebaseDB, "contracts"));

    if (querySnapshot.empty) return {} as IContract[];

    querySnapshot.forEach((doc) => {
      contracts.push(doc.data() as IContract);
      //console.log(doc.id, " => ", doc.data());
      console.log("Get all contracts" + new Date());
    });

    return contracts;
  } catch (error) {
    console.log(error);
    return [];
  }
}


const getLimited = async (maxRecords: number): Promise<IContract[]> => {
  try {
    let contracts: IContract[] = [];

    const q = query(
      collection(firebaseDB, "contracts"),
      limit(maxRecords));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return {} as IContract[];
    console.log("Get all contracts " + new Date());
    querySnapshot.forEach((doc) => {
      contracts.push(doc.data() as IContract);
    });

    contracts.forEach(async (c) => {
      c.payments = await PaymentService.getAll(c.id);
    });

    return contracts;
  } catch (error) {
    console.log(error);
    return [];
  }
}

const create = async (contract:IContract): Promise<Boolean> => {
  try {
    await setDoc(doc(firebaseDB, "contracts", contract.id.toString()), contract);
    contract.payments.forEach(async p => {
      await PaymentService.create(p, contract.id);
    });
    return true;
  } catch (error) {
    console.log(error);
    return false
  }
}

const findByUser = async (user: String | undefined | null) => {
  if (user == null || user == undefined) {
    return {} as IContract;
  }
  const response = await apiClient.get<IContract[]>(`/contract-by-user?userId=${user}`);
  return response.data;
}

const findById = async (id: any) => {
  const response = await apiClient.get<IContract>(`/contracts?id=${id}`);
  return response.data;
}

const findByTitle = async (user: string) => {
  const response = await apiClient.get<IContract[]>(`/contracts?title=${user}`);
  return response.data;
}

const isValidContract = async (contractId: string) => {
  const response = await apiClient.get<{ isValidContract: boolean }>("/contract", { params: { contractId: contractId } });
  return response.data.isValidContract;
}

const updateUsers = async (contractId: string, email: string) => {
  const response = await apiClient.patch<any>("/userContract", { contractId: contractId, email: email });
  return response.data;
}

const create0 = async (contract: IContract) => {
  const response = await apiClient.post<any>("/contracts", contract);
  return response.data;
}

const update = async (id: any, contract: IContract) => {
  //const response = await apiClient.put<any>(`/contracts/${id}`, contract);
  //return response.data;
  try {
    const q = query(
      collection(firebaseDB, "contracts"),
      limit(1), where("id", "==", id));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        console.log("Payment not found")
        return {} as IContract;
    }

    //let contract: IContract = querySnapshot.docs[0].data() as IContract;
    await updateDoc(querySnapshot.docs[0].ref, {
        ...contract
    });

    return contract;
} catch (error) {
    console.log(error);
    return {} as IContract;
}
}

const deleteById = async (id: any) => {
  const response = await apiClient.delete<any>(`/contracts/${id}`);
  return response.data;
}

const deleteAll = async () => {
  const response = await apiClient.delete<any>("/contracts");
  return response.data;
}

const ContractService = {
  findAll,
  findByUser,
  findById,
  findByTitle,
  isValidContract,
  updateUsers,
  create,
  update,
  deleteById,
  deleteAll,
  getLimited
}

export default ContractService;
