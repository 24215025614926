import * as React from 'react';
import {
    GridComponent, ColumnsDirective, ColumnDirective, Selection, Inject, Toolbar, Edit,
    Filter, Search, ColumnModel, ActionEventArgs, RowSelectEventArgs, EditSettingsModel,
    Page, IFilter, ValueAccessor, CommandColumn, Resize, Reorder, ExcelExport, Sort, SortSettingsModel
} from '@syncfusion/ej2-react-grids';

import { updateSampleSection } from './sample-base';
import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';
import '../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css';
import '../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css';
import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css';
import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
import "../../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap.css";

import { loadCldr } from "@syncfusion/ej2-base";

import * as gregorian from 'cldr-data/main/es-MX/ca-gregorian.json';
import * as numbers from 'cldr-data/main/es-MX/numbers.json';
import * as timeZoneNames from 'cldr-data/main/es-MX/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';// To load the culture based first day of week
import { setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { Internationalization } from '@syncfusion/ej2-base';

import * as XLSX from 'xlsx';


import { IContract, Payment } from '../../models/ContractDetails';

//Load the L10n from ej2-base
import { L10n } from '@syncfusion/ej2-base';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import PaymentService from '../../services/PaymentService';
import { RestaurantMenu } from '@mui/icons-material';
import ContractService from '../../services/ContractService';

setCulture('es-mx');

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
moment.locale("es-mx");

//load the locale object to set the localized placeholder value
L10n.load({
    'es-MX': {
        'datepicker': {
            today: 'Hoy'
        },
    }
});

type Props = { InitialContracts: IContract[] }


function MasterDetail(this: any, { InitialContracts }: Props) {

    React.useEffect(() => {
        updateSampleSection();
        console.log("RENDER")
    }, []);

    React.useEffect(() => {
        /*console.log("RENDER");
        document.getElementById(paymentsGrid.element.id + "_searchbar").addEventListener('keyup', () => {
            if (paymentsGrid)
            paymentsGrid.search(event.target.value);
        })*/
    });

    const [selectedContractPayments, setSelectedContractPayments] = React.useState<Payment[]>([])
    const [selectedPayment, setSelectedPayment] = React.useState<Payment>();
    const [selectedContract, setSelectedContract] = React.useState<IContract>();
    const [contracts, setContracts] = React.useState<IContract[]>(InitialContracts);
    const [conttractId, setContractId] = React.useState<String>("");

    const openLink = (e) => {
        window.open('https://pena.savants.com.mx/receipt?id=' + selectedPayment?.id, '_blank', 'noreferrer');
    };

    const commands0: any = [{ buttonOption: { content: "Ver Link", iconCss: ' e-icons e-link', cssClass: 'e-flat', click: openLink.bind(this), } }];

    const commands = [
        {
            buttonOption: {
                content: 'Details', cssClass: 'e-flat'
            }
        }
    ];
    const commandClick = (args) => {
        //if (paymentsGrid) {
        window.open('https://pena.savants.com.mx/receipt?id=' + args.rowData.id + '&contractId=' + selectedContract?.id, '_blank', 'noreferrer');
        //alert(JSON.stringify(args.rowData));
        //}
    };

    const gridTemplate = (props): any => {
        const src = props.EmployeeID + ".png";
        return (<GridComponent
            dataSource={selectedContract?.payments}
            created={created0}
            style={{ flex: "40%" }}
            allowSelection={true}
            ref={grid => paymentsGrid
                = grid}
            height="100%"
            toolbar={toolbarOptions}
            allowFiltering={true}
            filterSettings={{ mode: 'Immediate', immediateModeDelay: 500, type: 'Excel' }}
            editSettings={editSettings}
            actionBegin={actionBeginPaymentsGrid.bind(this)}
            allowPaging={true}
            dataBound={dataBound}
            locale='es-MX'
            searchSettings={searchOptions}
            rowSelected={selectPaymentRow.bind(this)}
            commandClick={commandClick}
            allowResizing={true}
            allowReordering={true}
        >
            <ColumnsDirective>

                <ColumnDirective field='periodDate0' headerText='Periodo' width='90'
                    format='MMM yyyy'
                    type='date'
                    editType="DatePickerEdit"
                    edit={{ params: { allowEdit: false, start: "Year", depth: "Year", locale: 'es-MX', format: 'MMM yyyy' } }}
                />
            </ColumnsDirective>
        </GridComponent>
        )
    }


    const searchOptions = {
        operator: 'contains',
        fields: ['periodDate']
    };

    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search', 'ExcelExport',
        { text: 'Reporte', tooltipText: 'Reporte', prefixIcon: 'e-expand', id: 'expandall' }];

    const toolbarClick = (args) => {
        if (contractsGrid && (args.item.id.includes('grid_') && args.item.id.includes('excelexport'))) {
            contractsGrid.excelExport();
        }

        if (contractsGrid && args.item.id.includes('expandall')) {

            var x: any = [];


            contracts.forEach(c => {
                var newContract: any = {};

                newContract.Manzana = c.block;
                newContract.Lote = c.lot.split(",")[0].padStart(3,"0");
                newContract.Contrato = c.id;
                newContract.Nombre = c.customer.name;
                newContract.Reserva = c.reserve;
                newContract.Tipo = c.type;
                newContract.MedicionAnterior = c.payments[1].currentRead;
                newContract.FechaMedicionAnterior = c.payments[1].period.toDate();
                newContract.MedicionActual =  c.payments[0].currentRead;
                newContract.FechaMedicionActual = c.payments[0].period.toDate();
                if (c.block == "X"){
                        
                } else { x.push(newContract);}
                
            })

            x.sort(function (a, b) {
                return a.Manzana.localeCompare(b.Manzana) || a.Lote - b.Lote;
            });

            const worksheet = XLSX.utils.json_to_sheet(x);
            worksheet['!cols'] = [{ width: 10 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "ReporteMediciones.xlsx");
        }
    };

    const editSettings: EditSettingsModel = {
        allowEditing: true, allowAdding: true, allowDeleting: true,
        newRowPosition: 'Top', showDeleteConfirmDialog: true, mode: 'Normal'
    };
    const editparams = { params: { popupHeight: '300px' } };
    const validationRule = { required: true };
    const orderidRules = { required: true, number: true };
    const pageSettings = { pageSize: 20, pageSizes: true };
    const format = { type: 'dateTime', format: 'M/d/y hh:mm a' };

    const key = null;
    const detail: Payment[] = [];
    let paymentsGrid: GridComponent | null;
    let contractsGrid: GridComponent | null;

    function actionBeginContractsGrid(args: ActionEventArgs) {
        if (args.requestType === 'save') {
            if (contractsGrid.pageSettings.currentPage !== 1 && contractsGrid.editSettings.newRowPosition === 'Top') {
                args.index = (contractsGrid.pageSettings.currentPage * contractsGrid.pageSettings.pageSize) - contractsGrid.pageSettings.pageSize;
            }
            else if (contractsGrid.editSettings.newRowPosition === 'Bottom') {
                args.index = (contractsGrid.pageSettings.currentPage * contractsGrid.pageSettings.pageSize) - 1;
            }
            updateContract(args.data as IContract);
        }
        console.log(args.requestType)
    }

    function actionBeginPaymentsGrid(args: ActionEventArgs) {
        if (args.requestType === 'save') {
            if (contractsGrid.pageSettings.currentPage !== 1 && contractsGrid.editSettings.newRowPosition === 'Top') {
                args.index = (contractsGrid.pageSettings.currentPage * contractsGrid.pageSettings.pageSize) - contractsGrid.pageSettings.pageSize;
            }
            else if (contractsGrid.editSettings.newRowPosition === 'Bottom') {
                args.index = (contractsGrid.pageSettings.currentPage * contractsGrid.pageSettings.pageSize) - 1;
            }
            args.data.periodDate = moment(args.data.periodDate0).format("MMM YYYY").replace(".", "");
            //let contract = selectedContract;
            let pays: Payment[] = selectedContract.payments;
            //let i = 0;
            let i = pays.findIndex(c => c.id === selectedPayment.id)
            pays[i] = args.data as Payment;


            //p.periodDate = args.data.periodDate;
            //setSelectedContract({...selectedContract} );            //console.log("SAVE: " + args.data.periodDate + contracts.find(c => c.id === selectedContract.id).id);
            //PaymentService.update(selectedPayment?.id, selectedContract?.id, args.data as Payment)
            //console.log({ ...selectedContract, payments: pays });
            setSelectedContract({ ...selectedContract, payments: pays })
            //setSelectedPayment(args.data as Payment)
            updatePayment(pays[i]);
            //console.log("SAVE: " + args.data.periodDate + contracts.find(c => c.id === selectedContract.id).id);
        }

        if (args.requestType === 'delete') {

            const p: Payment = args.data[0] as Payment;
            //PaymentService.deletePayment( p.id, selectedContract.id)

        }
        console.log(args.requestType)
    }

    function actionCompletePaymentsGrid(args: ActionEventArgs) {
        if (args.requestType === 'save') {
            if (contractsGrid.pageSettings.currentPage !== 1 && contractsGrid.editSettings.newRowPosition === 'Top') {
                args.index = (contractsGrid.pageSettings.currentPage * contractsGrid.pageSettings.pageSize) - contractsGrid.pageSettings.pageSize;
            }
            else if (contractsGrid.editSettings.newRowPosition === 'Bottom') {
                args.index = (contractsGrid.pageSettings.currentPage * contractsGrid.pageSettings.pageSize) - 1;
            }
            args.data.periodDate = moment(args.data.periodDate0).format("MMM YYYY").replace(".", "");
            //let contract = selectedContract;
            let pays: Payment[] = selectedContract.payments;
            //let i = 0;
            let i = pays.findIndex(c => c.id === selectedPayment.id)
            pays[i] = args.data as Payment;


            //p.periodDate = args.data.periodDate;
            //setSelectedContract({...selectedContract} );            //console.log("SAVE: " + args.data.periodDate + contracts.find(c => c.id === selectedContract.id).id);
            //PaymentService.update(selectedPayment?.id, selectedContract?.id, args.data as Payment)
            //console.log({ ...selectedContract, payments: pays });
            setSelectedContract({ ...selectedContract, payments: pays })
            //setSelectedPayment(args.data as Payment)
            console.log(pays[i]);
            //updatePayment();
            //console.log("SAVE: " + args.data.periodDate + contracts.find(c => c.id === selectedContract.id).id);
        }

        if (args.requestType === 'delete') {

            const p: Payment = args.data[0] as Payment;
            //PaymentService.deletePayment( p.id, selectedContract.id)

        }
        console.log(args.requestType)
    }


    function updatePayment(payment: Payment) {
        //PaymentService.update()

        //console.log(contracts[0].id)
        //const c = contracts;
        //c.find(c => c.id === selectedContract.id).id = "10";
        //setContracts(c)
        console.log(selectedContract?.id)
        console.log(selectedPayment)
        PaymentService.update(selectedPayment?.id, selectedContract?.id, payment);
        //selectedPayment.amount = 100;
        //paymentsGrid?.refresh();
        //contractsGrid?.refresh();
    }

    function updateContract(contract: IContract) {
        ContractService.update(contract.id, contract);
    }
    const created = () => {
        document.getElementById(contractsGrid.element.id + "_searchbar").addEventListener('keyup', () => {
            contractsGrid.search(event.target.value);
        });
    };
    const created2 = () => {
        document.getElementById(paymentsGrid.element.id + "_searchbar").addEventListener('keyup', () => {
            paymentsGrid.search(event.target.value);
        });
    };

    const created0 = () => {

    };

    const created1 = () => {
        console.log("created1");
    };


    function selectContractRow(args: RowSelectEventArgs) {
        console.log("ROW SELECT")
        const selRecord = args.data;
        if (selRecord) {
            let selecteMessage = document.getElementsByClassName('e-statustext')[0];
            let message = selecteMessage.querySelector('b');
            message.textContent = selRecord.customer.name;
            let payments = selRecord.payments;
            if (payments)
                payments.forEach(c => {
                    if (!c.period) {
                        c.periodDate = (moment().format('MMM YYYY').replace(".", ""));
                        return;
                    }
                    //console.log("id:" + c.id + " period:" + moment(c.period.seconds * 1000));
                    c.periodDate = (moment(c.period.seconds * 1000).format("MMM YYYY").replace(".", ""));
                    c.periodDate0 = new Date(c.period.seconds * 1000);
                    c.due = new Date(c.dueDate.seconds * 1000);
                    c.measurement = new Date(c.measurementDate.seconds * 1000);
                    c.paid = c.paidDate == undefined || c.paidDate == null ? new Date() : new Date(c.paidDate.seconds * 1000);

                    //console.log(moment(c.periodDate, "MMM YYYY").format())
                    //console.log(new Timestamp(+moment(c.periodDate, "MMM YYYY").format("X"), 0));
                })

            //paymentsGrid.dataSource = payments;
            setSelectedContract(selRecord as IContract);
        }
    }

    function selectPaymentRow(args: RowSelectEventArgs) {
        const p: Payment = args.data as Payment;
        //p.measurementDate = args.data?.
        setSelectedPayment(p);
    }

    const customProp = (field: string, data: any, column: ColumnModel) => {
        return data.Prop.SubProp;
    };

    function formatPeriod(field: string, data: Payment, column: ColumnModel): ValueAccessor | string {
        let value = data.period;
        if (value == null || value == undefined) {
            return "";
        }
        return new Date(value.seconds * 1000)
    }

    function formatDate(field: string, data: Payment, column: ColumnModel): ValueAccessor | string {
        let value = data.period;
        if (value == null || value == undefined) {
            return "";
        }
        return new Date(value.seconds * 1000).toLocaleString('es-MX', { day: '2-digit', month: 'short', year: 'numeric' })
    }

    const dataBound = () => {
        if (contractsGrid) {
            contractsGrid.autoFitColumns(['Periodo', 'Fecha Pago']);
        }
    };

    function dataBound0() {
        if (contractsGrid) {
            contractsGrid.autoFitColumns(['Periodo', 'Fecha Pago']);
        }
    };

    function getPeriodDate() {
        return (new Date(selectedPayment?.period.seconds * 1000)).toLocaleString('en-US', { month: 'short', year: 'numeric' });
    }

    const getP = () => {
        return new Date(selectedPayment?.period.seconds * 1000).toLocaleString('en-US', { month: 'short', year: 'numeric' });
    }

    const filterPeriod: IFilter = {
        type: 'CheckBox',
        itemTemplate: (e) => {
            if (e.period) {
                let value = e.period;
                //return e.period;
                return <span>{new Date(value.seconds * 1000).toLocaleString('es-MX', { month: 'short', year: 'numeric' })}</span>
            }
        }
    }

    const filterPeriod0: IFilter = {
        type: 'CheckBox',
        itemTemplate: (e) => {
            if (e.period) {
                let value = e.period;
                return <span>{new Date(value.seconds * 1000).toLocaleString('es-MX', { month: 'short', year: 'numeric' })}</span>
            }
        }
    }

    const filterDate: IFilter = {
        type: 'CheckBox',
        itemTemplate: (e) => {
            if (e.period) {
                let value = e.period;
                return <span>{new Date(value.seconds * 1000).toLocaleString('es-MX', { day: '2-digit', month: 'short', year: 'numeric' })}</span>     // here you can return your customized data 
            }
        }
    }

    const sortingOptions: SortSettingsModel = {
        columns: [{ field: 'block', direction: 'Ascending' }, { field: 'lot', direction: 'Ascending' }]
    };

    return (

        <div className='control-pane' style={{ flex: 0.8, alignContent: 'stretch', borderWidth: 10, borderColor: 'black', height: "45%" }}>
            <div className='control-section' style={{ height: "45%" }} >
                <GridComponent
                    dataSource={contracts}
                    created={created0}
                    style={{ flex: "40%" }}
                    rowSelected={selectContractRow.bind(this)}
                    toolbar={toolbarOptions}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowPaging={true}
                    editSettings={editSettings}
                    pageSettings={pageSettings}
                    actionBegin={actionBeginContractsGrid.bind(this)}
                    ref={grid => contractsGrid = grid}
                    height="100%"
                    allowFiltering={true}
                    filterSettings={{ mode: 'Immediate', immediateModeDelay: 500, type: 'Excel' }}
                    locale='es-MX'
                    allowResizing={true}
                    allowReordering={true}
                    allowExcelExport={true}
                    toolbarClick={toolbarClick}
                    sortSettings={sortingOptions}
                >
                    <ColumnsDirective>

                        <ColumnDirective field='block' headerText='M' width='20' />
                        <ColumnDirective field='lot' headerText='L' width='20' />
                        <ColumnDirective field='id' headerText='Contrato' width='50' ></ColumnDirective>
                        <ColumnDirective field='meterNumber' headerText='Medidor' width='90'></ColumnDirective>
                        <ColumnDirective field='customer.name' headerText='Nombre' width='170' />
                        <ColumnDirective field='type' headerText='Tipo' width='70' />
                        <ColumnDirective field='reserve' headerText='Reserva' width='70' />
                        <ColumnDirective field='status' headerText='Status' width='70' />


                    </ColumnsDirective>
                    <Inject services={[Selection, Toolbar, Edit, Filter, Search, Page, Resize, Reorder, ExcelExport, Sort]} />
                </GridComponent>

                <div className='e-statustext'> Pagos del cliente:  <b></b></div>

                <GridComponent
                    dataSource={selectedContract?.payments}
                    created={created0}
                    style={{ flex: "40%" }}
                    allowSelection={true}
                    ref={grid => paymentsGrid
                        = grid}
                    height="100%"
                    toolbar={toolbarOptions}
                    allowFiltering={true}
                    filterSettings={{ mode: 'Immediate', immediateModeDelay: 500, type: 'Excel' }}
                    editSettings={editSettings}
                    actionBegin={actionBeginPaymentsGrid.bind(this)}
                    allowPaging={true}
                    dataBound={dataBound}
                    locale='es-MX'
                    searchSettings={searchOptions}
                    rowSelected={selectPaymentRow.bind(this)}
                    commandClick={commandClick}
                    allowResizing={true}
                    allowReordering={true}
                >
                    <ColumnsDirective>

                        <ColumnDirective field='periodDate0' headerText='Periodo' width='90'
                            format='MMM yyyy'
                            type='date'
                            editType="DatePickerEdit"
                            edit={{ params: { allowEdit: false, start: "Year", depth: "Year", locale: 'es-MX', format: 'MMM yyyy' } }}
                        />
                        <ColumnDirective field='amount' headerText='Monto' width='110'
                            editType="numericedit"
                            edit={{ params: { allowEdit: false, format: 'c2' }, }}
                            format="c2" />
                        <ColumnDirective field='debt' headerText='Adeudo' width='110'
                            editType="numericedit"
                            edit={{ params: { allowEdit: false, format: 'c2' }, }}
                            format="c2" />
                        <ColumnDirective field='otherCharges' headerText='Cargos' width='110'
                            editType="numericedit"
                            edit={{ params: { allowEdit: false, format: 'c2' }, }}
                            format="c2" />
                        <ColumnDirective field='totalAmount' headerText='Total' width='110'
                            editType="numericedit"
                            edit={{ params: { allowEdit: false, format: 'c2' }, }}
                            format="c2" />
                        <ColumnDirective field='paid' headerText='Fecha Pago' width='100' type='date'
                            format='dd-MMM-yyyy'
                            editType="DatePickerEdit"
                            edit={{ params: { allowEdit: false, format: 'dd-MMM-yyyy' } }}

                        />
                        <ColumnDirective field='status' headerText='Status' width='110' />
                        <ColumnDirective field='usage' headerText='Consumo' width='100' />
                        <ColumnDirective field='unitPrice' headerText='Tarifa' width='100' />
                        <ColumnDirective field='previousRead' headerText='L Anterior' width='110' />
                        <ColumnDirective field='currentRead' headerText='L Actual' width='110' />

                        <ColumnDirective field='due' headerText='Vence' width='110'
                            format='dd-MMM-yyyy'
                            editType="DatePickerEdit"
                            edit={{ params: { allowEdit: false, format: 'dd-MMM-yyyy' } }}
                        />
                        <ColumnDirective field='measurement' headerText='Medicion' width='110'
                            format='dd-MMM-yyyy'
                            editType="DatePickerEdit"
                            edit={{ params: { allowEdit: false, format: 'dd-MMM-yyyy' } }}
                        />
                        <ColumnDirective headerText='Ver Recibo' width='100' commands={commands} type='command' />
                    </ColumnsDirective>
                    <Inject services={[Selection, Toolbar, Edit, Filter, Page, CommandColumn, Resize, Reorder]} />
                </GridComponent>
            </div>
        </div >);
}
export default MasterDetail;


