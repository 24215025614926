import React from 'react';
import 'react-native-gesture-handler';
import { NavigationContainer } from '@react-navigation/native';
import { View, Text, Image } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
//import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from '../screens/HomeScreen';
import ContractsScreen from '../screens/ContractsScreen';
import { StatusBar, StyleSheet } from 'react-native';
import { colors } from '../colors';
import { i18n } from '../i18n'
import { AuthenticatedUserContext } from '../providers';

import { LogBox } from 'react-native';
import { UserContextType } from '../@types/user';
LogBox.ignoreAllLogs();


const Stack = createStackNavigator();


export const AppStack = () => {
  const { user, setUser } = React.useContext(AuthenticatedUserContext) as UserContextType;
  return (
    <>
      <StatusBar
        backgroundColor={colors.blurple_dark}
        barStyle="light-content"
        translucent
      />

      <Stack.Navigator
        screenOptions={{
          headerTintColor: colors.white,
          headerStyle: {
            shadowOpacity: 0,
            //backgroundColor: colors.blurple,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: colors.slate,
          },
          headerTitleStyle: {
            color: colors.white,
            fontSize: 25
          },
          headerBackTitleStyle: {
            color: colors.white,
          },
          headerBackground: () => (
            <Image
              style={StyleSheet.absoluteFill}
              source={{ uri: 'https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1594&q=80' }}
            />)
        }}
      >
        <Stack.Screen name="Administración de Recibos de Agua" component={ContractsScreen} />
      </Stack.Navigator>

    </>
  );
}

