import React, { useCallback } from 'react';
import { Pressable, Text, StyleSheet } from 'react-native';

import { colors } from '../colors';

type Props = {
  children?: any,
  onPress?: any,
  activeOpacity?: number,
  borderless?: boolean,
  title?: string,
  style?: any
}

export const Button2: React.FC<Props> = ({
  children,
  onPress,
  activeOpacity = 0.3,
  borderless = false,
  title,
  style
}) => {
  const _style = useCallback(({ pressed }: { pressed: any }) => [
    style,
    { opacity: pressed ? activeOpacity : 1 }
  ], []);

  if (borderless) {
    return (
      <Pressable onPress={onPress} style={_style}>
        <Text style={styles.borderlessButtonText}>{title}</Text>
      </Pressable>
    );
  }

  return (
    <Pressable onPress={onPress} style={_style}>
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  borderlessButtonText: {
    fontSize: 20,
    color: colors.brown_font
  }
});
