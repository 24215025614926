import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { colors } from '../colors';

interface Props {
  error: string | undefined;
  visible: boolean | undefined;
}


export const FormErrorMessage = ({ error, visible }: Props) => {
  if (!error || !visible) {
    return null;
  }

  return <Text style={styles.errorText}>{error}</Text>;
};

const styles = StyleSheet.create({
  errorText: {
    marginLeft: 15,
    color: colors.red,
    fontSize: 16,
    marginVertical: 8,
    fontWeight: '600'
  }
});
